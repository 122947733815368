body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.e-taskbar-left-resizer,
.e-taskbar-right-resizer {
  position: absolute !important;
}

.e-taskbar-left-resizer,
.e-taskbar-right-resizer {
  top: 3px !important;
}

.e-taskbar-main-container {
  height: 20px !important;
  margin: 0px !important;
}

.e-left-label-container {
  height: 20px !important;
}

.e-leftarc,
.e-rightarc,
.e-manualparent-main-container {
  top: 1.5px !important;
}

.e-manualparent-main-container {
  height: 10px !important;
}

.e-gantt-manualparenttaskbar-left,
.e-gantt-manualparenttaskbar-right {
  height: 100% !important;
  cursor: default !important;
}

.e-rowcell,
.e-headercell {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.e-grid .e-headercell {
  background-color: #eaeaea;
  font-size: 12px !important;
  color: rgba(46, 46, 46, 0.6);
}

.e-headercelldiv {
  padding-left: 5px !important;
  padding-right: 23px !important;
  font-size: 12px !important;
}

.dark-mode .e-headercelldiv {
  color: white !important;
}

.e-btn .e-btn-icon {
  font-size: 12px !important;
}

.e-sortfilterdiv {
  margin-top: -16px !important;
  margin-bottom: 0px !important;
  margin-left: 0px !important;
  margin-right: 20px !important;
}

.e-sortnumber {
  position: absolute !important;
  top: -5px !important;
  right: 35px !important;
}

.e-columnmenu {
  padding-left: 4px !important;
  padding-right: 4px !important;
  margin-top: -19px !important;
  margin-bottom: 0px !important;
  margin-left: 0px !important;
  margin-right: -21px !important;
}

.e-indicator-span {
  z-index: 10;
  display: inline-flex !important;
  line-height: 16px !important;
  background-color: black;
  border-radius: 4px;
  border-color: red;
  border-width: 3px;
  color: white !important;
  margin: 0px !important;
  padding: 2px;
}

.e-toolbar .e-toolbar-items .e-toolbar-item.hide-add-and-edit-resource-button {
  display: none;
}

.e-ddt .e-chips-wrapper {
  width: auto;
}

.hide-checkbox-on-project {
  opacity: 0;
}

.highlight-flagged-role td {
  background-color: yellow;
}

.e-gantt-dialog .e-edit-form-row,
.e-gantt-dialog .e-item {
  height: fit-content;
}

#root
  > div
  > main
  > header.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionFixed.jss6.mui-fixed.css-1s7ggur
  > div
  > div
  > div:nth-child(2)
  > div
  > div:nth-child(2)
  > div
  > fieldset {
  background-color: transparent !important;
  top: -13px !important;
}

#root
  > div
  > main
  > header.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionFixed.jss6.mui-fixed.css-1s7ggur
  > div
  > div
  > div:nth-child(2)
  > div
  > div:nth-child(2)
  > div
  > fieldset
  > legend {
  background-color: transparent !important;
  padding: 0px !important;
  color: transparent !important;
}

#root
  > div
  > main
  > header.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionFixed.makeStyles-appBar-6.mui-fixed.css-1my8sg9-MuiPaper-root-MuiAppBar-root
  > div
  > div
  > div:nth-child(2)
  > div
  > div:nth-child(1)
  > p,
#root
  > div
  > main
  > header.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionFixed.makeStyles-appBar-6.mui-fixed.css-1my8sg9-MuiPaper-root-MuiAppBar-root
  > div
  > div
  > div:nth-child(1)
  > div
  > div:nth-child(3)
  > p,
#root
  > div
  > main
  > header.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionFixed.makeStyles-appBar-6.mui-fixed.css-1my8sg9-MuiPaper-root-MuiAppBar-root
  > div
  > div
  > div:nth-child(2)
  > div
  > div:nth-child(3)
  > a
  > button {
  color: white !important;
}

#root
  > div
  > main
  > header.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionFixed.makeStyles-appBar-6.mui-fixed.css-1my8sg9-MuiPaper-root-MuiAppBar-root
  > div
  > div
  > div:nth-child(2)
  > div
  > div:nth-child(3)
  > a
  > button,
#root
  > div
  > main
  > header.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionFixed.makeStyles-appBar-6.mui-fixed.css-1my8sg9-MuiPaper-root-MuiAppBar-root
  > div
  > div
  > div:nth-child(1)
  > div
  > div:nth-child(1)
  > div
  > button {
  padding: 12px !important;
}

#printable > div {
  padding: 0px !important;
}

#ProjectsGrid > div.e-gridcontent > div > div.e-virtualtable,
#PersonnelGrid > div.e-gridcontent > div > div.e-virtualtable {
  transform: translate(0px, 0px) !important;
}

.e-grid .e-rowcell.e-unboundcell {
  text-align: center !important;
}

.e-grid .e-rowcell {
  font-size: 13px !important;
}

.e-commandButton {
  padding: 0px !important;
  margin: 3px !important;
}

.e-toolbar .e-toolbar-item .e-tbar-btn {
  padding: 0px !important;
}

.e-toolbar .e-toolbar-item .e-tbar-btn:active {
  background-color: #bdbdbd !important;
}

.e-toolbar .e-toolbar-item .e-tbar-btn:active .e-tbar-btn-text {
  color: black !important;
}

.e-toolbar-item.clear-all-filters > .e-tbar-btn > .e-tbar-btn-text {
  color: #a6192e !important;
}

#ProjectsGrid_searchbar,
#ProjectsUnfilledGrid_searchbar,
#ProjectsGantt_searchbar,
#PersonnelGrid_searchbar,
#PersonnelUnassignedGrid_searchbar,
#PersonnelGantt_searchbar,
#ConsultantGrid_searchbar {
  padding: 5px 7px;
}

#ProjectsGrid_searchbutton,
#ProjectsUnfilledGrid_searchbutton,
#ProjectsGantt_searchbutton,
#PersonnelGrid_searchbutton,
#PersonnelUnassignedGrid_searchbutton,
#PersonnelGantt_searchbutton,
#ConsultantGrid_searchbutton {
  display: none;
}

.e-grid .e-toolbar-item.e-cc.e-ccdiv.e-cc-toolbar {
  margin-right: 10px !important;
}

.e-gantt .e-gantt-tree-grid-pane .e-columnheader,
.e-gantt .e-gantt-tree-grid-pane .e-headercell,
.e-gantt .e-gantt-chart .e-timeline-header-container,
.e-toolbar-left,
.e-tbar-btn .e-tbtn-txt .e-control .e-btn .e-lib,
.e-gantt .e-gantt-chart .e-timeline-header-containe {
  height: 42px !important;
}

.dark-mode .e-gantt .e-gantt-chart .e-timeline-header-container {
  height: 52px !important;
}

.dark-mode .e-toolbar .e-toolbar-item .e-tbar-btn .e-icons.e-btn-icon {
  min-height: fit-content !important;
}

.e-gantt .e-gantt-chart .e-timeline-top-header-cell {
  height: 21px !important;
}

.e-gantt .e-gantt-chart .e-gantt-parent-taskbar-inner-div {
  background-color: rgba(97, 97, 97, 0.87) !important;
}

.e-gantt .e-gantt-chart .e-gantt-child-taskbar-inner-div {
  background: #5869c5 !important;
}

.e-grid td.e-active {
  background-color: rgba(0, 0, 0, 0) !important;
}

#PersonnelGrid .e-rowcell.e-selectionbackground.e-active,
#PersonnelUnassignedGrid .e-rowcell.e-selectionbackground.e-active,
#ConsultantGrid .e-rowcell.e-selectionbackground.e-active,
#ProjectsGrid .e-rowcell.e-selectionbackground.e-active,
#ProjectsUnfilledGrid .e-rowcell.e-selectionbackground.e-active,
#PersonnelGantt .e-chart-row.e-active,
.e-gantt .e-gantt-chart .e-active,
#ProjectsGantt .e-rowcell.e-selectionbackground.e-active,
#PersonnelGantt .e-rowcell.e-selectionbackground.e-active {
  background-color: #feb431 !important;
  color: black !important;
}

/* change the icon colors black when selecting row in dark mode */
.dark-mode #PersonnelGrid .e-rowcell.e-selectionbackground.e-active .e-btn .e-btn-icon,
.dark-mode #PersonnelUnassignedGrid .e-rowcell.e-selectionbackground.e-active .e-btn .e-btn-icon,
.dark-mode #ConsultantGrid .e-rowcell.e-selectionbackground.e-active .e-btn .e-btn-icon,
.dark-mode #ProjectsGrid .e-rowcell.e-selectionbackground.e-active .e-btn .e-btn-icon,
.dark-mode #ProjectsUnfilledGrid .e-rowcell.e-selectionbackground.e-active .e-btn .e-btn-icon,
.dark-mode #PersonnelGantt .e-chart-row.e-active .e-btn .e-btn-icon,
.dark-mode .e-gantt .e-gantt-chart .e-active .e-btn .e-btn-icon,
.dark-mode #ProjectsGantt .e-rowcell.e-selectionbackground.e-active .e-btn .e-btn-icon,
.dark-mode #PersonnelGantt .e-rowcell.e-selectionbackground.e-active .e-btn .e-btn-icon {
  color: black !important;
}
